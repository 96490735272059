import { MerchantData, sectionLabel, ValidationSchema } from "./data/data";
import { H4, H5 } from "components/typography/Typography";
import {
  Paper,
  CircularProgress,
  Link,
  Box,
  Grid,
  Snackbar,
  Alert,
  Backdrop,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { cloneElement, useEffect, useState } from "react";
import _ from "lodash";
import { getKycById } from "services/https/updatedKYCService";
import { StyledKYCDetails, Item } from "./Styled";
import { useSelector } from "react-redux";
import {
  DialogFactory,
  dialogOptions,
} from "components/dialog/dialogFactory/DialogFactory";
import { InputBox } from "./KYCInputBoxList";
import { RejectAcceptComponent, TopActionBar } from "./Component";
import { getDirectorKYC } from "services/https/kycService";

export const KYCDetail = () => {
  const [editable, setEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userId } = useParams();
  const [merchantData, setMerchantData] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [kycInfo, setKYCInfo] = useState();

  const [openBackDrop, setOpenBackDrop] = useState(false);
  const {
    alerts: { dialogType, alertType, msg },
  } = useSelector((state) => {
    return state;
  });
  const [updatedChanges, setUpdatedChanges] = useState(merchantData);
  useEffect(() => {
    setIsLoading(true);
    getKycById({ merchantId: userId }).then((data) => {
      const {
        businessIndustry: { name },
      } = data || { businessIndustry: "" };
      setIsLoading(false);
      console.log(data);
      setMerchantData({ ...data, businessIndustry: name });

      console.log("info here 1", merchantData);
    });
    getDirectorKYC({ merchantId: userId }).then((data) => {
      setKYCInfo(data);
      console.log("my test", data);
      setMerchantData({ ...data, directorNinImageUrl:data?.directorNinImageUrl, directorPhotoUrl: data?.directorPhotoUrl });

    });

  }, [userId, merchantData?.length]);

  const AsideDiv = () => {
    return (
      <Grid item sx={{ position: "fixed", left: "72%", p: 4 }}>
        <Item>
          <Link variant={"h6"} underline={"hover"} href={"#profileInfo"}>
            {"Profile Information"}
          </Link>
          <Link variant={"h6"} underline={"none"} href={"#contactInfo"}>
            {"Contact Information"}
          </Link>
          <Link variant={"h6"} underline={"none"} href={"#acctInfo"}>
            {"Account Details"}
          </Link>
          <Link variant={"h6"} underline={"none"} href={"#documentUpload"}>
            {"Document Upload"}
          </Link>
        </Item>
      </Grid>
    );
  };

  return (
    <StyledKYCDetails>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackDrop}
        onClick={() => {}}
      />
      {Boolean(dialogType) && (
        <DialogFactory
          render={() => {
            return cloneElement(dialogOptions[dialogType], {
              openDialog,
              setOpenDialog,
            });
          }}
        />
      )}
      <Snackbar
        open={Boolean(alertType)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          open={Boolean(alertType)}
          severity={alertType}
          sx={{ width: "100%", fontWeight: "bold" }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <Box className="detailContainer">
        <Grid className={"gridContainer"} container>
          {!isLoading ? (
            merchantData && (
              <>
                <Grid item xs={8}>
                  <TopActionBar
                    editable={editable}
                    setEditable={setEditable}
                    merchantData={merchantData}
                    updatedChanges={updatedChanges}
                    openBackDrop={openBackDrop}
                    setOpenBackDrop={setOpenBackDrop}
                  />
                  <Paper elevation={0} sx={{ paddingBlockEnd: 10 }}>
                    <form>
                      {Object.entries(sectionLabel).map(
                        ([sectionKey, sectionLabel]) => {
                          return (
                            <section
                              id={sectionKey}
                              key={sectionKey}
                              style={{ padding: "20px 0" }}
                            >
                              <H4 className={"sectionLabel"}>{sectionLabel}</H4>
                              {MerchantData[sectionKey]?.map(
                                ({
                                  label,
                                  subLabel,
                                  items,
                                  infoText,
                                  ...others
                                }) => {
                                  return (
                                    <>
                                      {!_.isEmpty(items) && (
                                        <>
                                          <H5 className={"subSectionLabel"}>
                                            {subLabel}
                                          </H5>
                                          {items?.map(
                                            ({
                                              label,
                                              selected,
                                              ...others
                                            }) => (
                                              <>
                                                <InputBox
                                                  label={label}
                                                  others={others}
                                                  setOpenDialog={setOpenDialog}
                                                  editable={editable}
                                                  data={merchantData}
                                                  updatedChanges={
                                                    updatedChanges
                                                  }
                                                  setUpdatedChanges={
                                                    setUpdatedChanges
                                                  }
                                                />
                                              </>
                                            )
                                          )}
                                        </>
                                      )}

                                      {!Boolean(subLabel) && (
                                        <>
                                          <InputBox
                                            label={label}
                                            others={others}
                                            editable={editable}
                                            data={merchantData}
                                            updatedChanges={updatedChanges}
                                            setUpdatedChanges={
                                              setUpdatedChanges
                                            }
                                          />
                                        </>
                                      )}
                                    </>
                                  );
                                }
                              )}
                            </section>
                          );
                        }
                      )}
                    </form>
                  </Paper>
                  {!editable && (
                    <RejectAcceptComponent
                      merchantId={merchantData?.merchantId}
                      setOpenDialog={setOpenDialog}
                      openDialog={openDialog}
                    />
                  )}
                </Grid>
                <AsideDiv />
              </>
            )
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Box>
    </StyledKYCDetails>
  );
};
