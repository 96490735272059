import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputBase,
  MenuItem,
  Select,
} from "@mui/material";
import { Span } from "components/typography/Typography";
import _, { capitalize } from "lodash";
import { txRoutingLabel } from "pages/tech/pages/transactionRouting/data";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "redux/actions/AlertActions";
import {
  getAllProcessors,
  getProcessorList,
  updateProcessorList,
} from "services/https/techService";
import { StyledBox } from "../shared/sharedStyles";
import { getAllRoles } from "services/https/roleAndAccessService";
import styled from "styled-components";

const AdminModal = ({ openDialog, setOpenDialog }) => {
  const {
    alerts: { payload },
  } = useSelector((state) => {
    return state;
  });

  const { title, subTitle, handleOk, row, type } = payload || {};
  const [option, setOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [role, setRole] = useState(row?.role || "");

  const handleClick = (e) => {
    setLoading(true);
    const roleId = option.find((item) => item.name === role);
    handleOk(roleId?.id).finally(() => {
      setLoading(false);
      handleClose();
    });
  };

  const handleChange = (e) => {
    setRole(e.target.value);
  };

  const handleClose = () => {
    setRole("");
    setOpenDialog(false);
  };

  useEffect(() => {
    getAllRoles().then((data) => {
      const myData = JSON.parse(data).map((i) => {
        return { ...i, ["name"]: i.role };
      });
      setOption(myData);
    });
  }, []);

  return (
    <Box sx={{ width: "100%", maxWidth: 360 }}>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={openDialog}
        onClose={handleClose}
      >
        <HeadContainer>
          <p className="role-header-text">{`${type} Team Member’s Role`} </p>
        </HeadContainer>
        <RoleHeader>
          Name: <span className="role-head-text">{row?.fullName}</span>
        </RoleHeader>
        <RoleHeader>
          Current Role: <span className="role-head-text">{row?.role}</span>
        </RoleHeader>
        <DialogContent>
          <StyledBox>
            <FormControl fullWidth>
              <label htmlFor="select-processor">{"Select New Role"}</label>
              <Select
                placeholder={"Select New Processor"}
                value={role}
                onChange={handleChange}
                labelId="select-processor"
              >
                {option.map(({ name, id }, idx) => (
                  <MenuItem key={idx} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </StyledBox>
        </DialogContent>
        <DialogActions>
        <Button
            variant={"outlined"}
            fullWidth
            sx={{ mx: 2 }}
            onClick={handleClose}
          >
            <Span>{"Cancel"}</Span>
            {loading && (
              <Span sx={{ ml: 1, mt: "4px" }}>
                <CircularProgress sx={{ color: "#fff" }} size={20} />
              </Span>
            )}
          </Button>
          <Button
            variant={"contained"}
            fullWidth
            sx={{ mx: 2 }}
            onClick={handleClick}
            disabled={!Boolean(role == row?.role || role)}
          >
            <Span>{"Save Changes"}</Span>
            {loading && (
              <Span sx={{ ml: 1, mt: "4px" }}>
                <CircularProgress sx={{ color: "#fff" }} size={20} />
              </Span>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminModal;

const HeadContainer = styled.div`
  width: 100%;
  background-color: #fff0eb;
  text-align: center;
  .role-header-text {
    color:#8C3819;
    font-weight: 700;
    font-size: 20px;
  }
`;
const RoleHeader = styled.h3`
  font-weight: 500;
  font-size: 18px;
  margin: 15px 0 0 20px;
  .role-head-text {
    font-weight: 700;
    font-size: 16px;
    text-transform: capitalize;
  }
`;
 