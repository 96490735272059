import { Box, Button, Stack, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import CustomIcon from "assets/icons";
import { StyledTableCell } from "./UserTableMenu";
import { useDispatch } from "react-redux";
import {
  createDialog,
  notifyFailure,
  notifySuccess,
} from "redux/actions/AlertActions";
import { changeAdminRole, deleteAdminRole } from "services/https/roleAndAccessService";
import { ADMIN_MODAL, DELETE_ADMIN_MODAL, KYC_BUSINESS_INDUSTRY } from "utils/constant";
import { createRequest } from "services/https/fetchers";

const AdminUserTableMenu = ({ setOpenDialog, row }) => {
  const dispatch = useDispatch();
  const handleDeleteOk = () => {
    deleteAdminRole({ userId: row?.userId })
      .then((data) =>
        dispatch(
          notifySuccess({ msg: "Role Deleted successfully" })
        )
      )
      .catch((err) => notifyFailure({ msg: "Cannot delete Role" }))
      .finally(() => {
        setOpenDialog(false);
      });
  };

  const handleDelete = () => {
    const payload = {
      title: "Confirm Delete",
      row,
      subTitle: "Are you sure you want to delete this Role",
      handleOk: handleDeleteOk,
    };
    dispatch(createDialog({ type: DELETE_ADMIN_MODAL, payload }));
    setOpenDialog(true);
  };

  const handleOk = async (roleId) => {
     // await changeAdminRole({ userId: row?.userId, roleId });
    let d = new Date();
    let payload = {
     customerName: `${row?.fullName}`,
     payload: JSON.stringify({ userId: row?.userId, roleId }),
     requestId: d.getTime(),
     requestType: "changeRole"
   }

    try {
        await createRequest(payload);
    //  await changeAdminRole({ userId: row?.userId, roleId });
      return dispatch(
        notifySuccess({ msg: "Admin role successfully updated" })
      );
    } catch (err) {
      return notifyFailure({ msg: "Admin role update failed" });
    }
  };
  const handleClick = () => {
    setOpenDialog(true);
    const payload = {
      handleOk,
      row,
      title: "Change Admin Role",
      type: "Change",
    };
    dispatch(createDialog({ type: ADMIN_MODAL, payload }));
  };
  return (
    <StyledTableCell align="right" sx={{ paddingRight: "2em"  }}>
      <Box>
        <Stack direction={"row"} spacing={2}>
          <Button variant="outlined" onClick={handleClick}>{"Change Role"}</Button>
          <IconButton color="error" onClick={handleDelete}>
            <Delete />
          </IconButton>
        </Stack>
      </Box>
    </StyledTableCell>
  );
};

export default AdminUserTableMenu;
