import axios from "axios";
import { notifyFailure, notifySuccess } from "redux/actions/AlertActions";
import CONFIG from "../../config";


const adminRoleUrl = `${CONFIG.BASE_URL}/wayapay-role-access/admin`;
const adminRoleBaseUrl = `${CONFIG.BASE_URL}/wayapay-role-access`;

export const getAllRoles = async () => {
  try {
    const res = await axios.get(`${adminRoleUrl}/list-roles`);
    return res.data.data;
  } catch (err) {
    console.log(err);
  }
};

export const getMerchantLoginId = async (userId) => {
  try {
    const res = await axios.get(`${adminRoleUrl}/auth-service/api/v1/profile/${userId}`);
    return res.data.data;
  } catch (err) {
    console.log(err);
  }
};

export const inviteNewTeamMember = async (invitePayload) => {
  axios
  .post(`${adminRoleBaseUrl}/merchant/team/invite`, invitePayload)
  .then((res) => res.data)
  .catch((err) => {
    if (err.response.status === 400) {
      return err.response.data;
    } else {
      console.log(err.res)
    }
  });
};

export const createAdminUser = (pojo) =>
  axios
    .post(`${adminRoleUrl}/create-user`, pojo)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 400) {
        return err.response.data;
      } else {
        // Handle else
      }
    });

export const getAdminUserList = async () =>
await  axios
    .get(`${adminRoleUrl}/list-users-details`)
    .then((res) => res.data)
    .catch((err) => {
      if (err?.response?.status === 400) {
        return err.response.data;
      } else {
        console.log("err");
        // Handle else
      }
    });

export const getAdminUserListStats = async () =>
 await axios
    .get(`${adminRoleUrl}/list-users`)
    .then((res) => res.data)
    .catch((err) => {
      if (err?.response?.status === 400) {
        return err.response.data;
      } else {
        console.log("err");
        // Handle else
      }
    });

export const getAllPermisions = async () => {
  try {
    const res = await axios.get(`${adminRoleUrl}/list-permissions`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

// export const createCustomRole = async (pojo) => {
//   try {
//     return await axios.post(`${adminRoleUrl}/create/custom-roles`, pojo);
//   } catch (err) {
//     if (err.response.status === 400) {
//       return err.response.data;
//     } else {
//     }
//   }
// };

export const getRolePermisionList = (roleId) => {
  return axios
    .get(`${adminRoleUrl}/list/role-permissions/${roleId}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const deleteAdminRole = async ({ userId }) => {
  return await axios
    .post(`${adminRoleUrl}/remove_user?userId=${userId}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const changeAdminRole = async ({ roleId, userId }) => {

  return await axios
    .post(`${adminRoleUrl}/update/user-role?userId=${userId}&roleId=${roleId}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const createCustomRole = (payload) =>
  axios
    .post(`${adminRoleUrl}/create/custom-roles`, payload)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 400) {
        return err.response.data;
      } else {
        // Handle els
      }
    });

    export const getUserDetails = async ({ emailAddress }) => {
      return await axios
        .get(`${adminRoleUrl}/get-user/${emailAddress}`)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw new Error(err);
        });
    };

    export const editRole = (payload) =>
  axios
    .post(`${adminRoleUrl}/update-role`, payload)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });

    export const deleteRole = async ({ roleId }) => {
      return await axios
        .post(`${adminRoleUrl}/remove-role?roleId=${roleId}`)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw new Error(err);
        });
    };
