import React, { useEffect, useState, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import {
  Paper,
  Grid,
  ListItemText,
  ListItem,
  ListItemIcon,
  List,
  ListItemButton,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createDialog } from "redux/actions/AlertActions";
import { CREATE_CUSTOM_ROLE } from "utils/constant";
import {
  DialogFactory,
  dialogOptions,
} from "components/dialog/dialogFactory/DialogFactory";
import { cloneElement } from "react";
import {
  getAllPermisions,
  getAllRoles,
  getRolePermisionList,
} from "services/https/roleAndAccessService";
import { SuperAdmin } from "./SuperAdmin";
import { EDIT_CUSTOM_ROLE } from "utils/constant";
import { ArrowBack } from "@mui/icons-material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  header: {
    h3: {
      textAlign: "start",
      padding: theme.spacing(2),
    },
  },
}));

const ManageAdminUsersRoles = () => {
  const theme = useTheme();
  const [roles, setRoles] = useState({
    "Super Admin": "Super Admin",
    admin: "Admin",
  });
  const [selectedIndex, setSelectedIndex] = useState("ALL ROLES");
  const [selectedRoleId, setSelectedRoleId] = useState();
  const [allPermissions, setAllPermissions] = useState();
  const [permission, setPermission] = useState();
  const [roleDescription, setRoleDescription] = useState("");
  const [noPermission, setNoPermission] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [activeRole, setActiveRole] = useState();

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const handleListItemClick = (roleId, index) => {
    setSelectedRoleId(roleId);
    setSelectedIndex(index);
  };

  const {
    alerts: { alertType, msg, dialogType, payload },
  } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    const roles = {};
    getAllRoles().then((data) => {
      JSON.parse(data)?.map(({ id, role }) => {
        if (id && role) {
          roles[role] = { role, id };
        }
      });
      setRoles(roles);
    });
    getAllPermisions().then((data) => {
      const p = data.map((item) => item);
      setAllPermissions(p);
    });
  }, [msg]);

  useEffect(() => {
    if (selectedRoleId) {
      getRolePermisionList(selectedRoleId).then((data) => {
        const p = data?.data?.permissions?.map((item) => item);
        const nP = data?.data?.noPriviledge?.map((item) => item);
        setRoleDescription(data?.data?.description || "");
        setNoPermission(nP || []);
        setPermission(p || []);
      });
    }
  }, [selectedRoleId, selectedIndex]);

  const handleBtnClick = (e, type) => {
    dispatch(createDialog({ type }));
    setOpenDialog(true);
  };

  return (
    <Wrapper>
      <Button
        variant="text"
        startIcon={<ArrowBack />}
        onClick={() => navigate(-1)}
      >
        {"Back"}
      </Button>

      <HeadText>Manage Roles</HeadText>
      <HeadDescription>
        Manage team roles and their accesses here
      </HeadDescription>

      {Boolean(dialogType) && (
        <DialogFactory
          render={() => {
            return cloneElement(dialogOptions[dialogType], {
              openDialog,
              setOpenDialog,
              payload: {
                roleId: roles[selectedIndex]?.id,
                roleName: selectedIndex,
                roleDescription,
                rolePermissions: permission,
                allPermissions,
              },
            });
          }}
        />
      )}
      <Snackbar
        open={Boolean(alertType)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          open={Boolean(alertType)}
          severity={alertType || "info"}
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <Container>
        <Grid container spacing={2} sx={{ padding: "10px 10px" }}>
          <Grid item xs={2}>
            <RoleListSection>
              <header>
                <H3>{"Default Roles"}</H3>
              </header>
              <RoleList>
                {Object.entries(roles).map(([key, values], idx) => (
                  <li
                    className={
                      activeRole === values.id
                        ? "active-role-item role-list-item"
                        : "role-list-item"
                    }
                    key={idx}
                  >
                    <RoleListItemButton
                      onClick={(event) => {
                        setActiveRole(values.id);
                        handleListItemClick(values.id, key);
                      }}
                    >
                      {key.toLocaleLowerCase()}
                    </RoleListItemButton>

                    {/* <ListItemButton
                      selected={selectedIndex === key}
                      sx={{
                        borderBottom: `1px solid ${theme.palette.text.secondary}`,
                      }}
                      onClick={(event) => handleListItemClick(values.id, key)}
                    >
                      <ListItemText primary={key} />
                    </ListItemButton> */}
                  </li>
                ))}
              </RoleList>
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={(e) => handleBtnClick(e, CREATE_CUSTOM_ROLE)}
                  >
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Add Role" />
                  </ListItemButton>
                </ListItem>
              </List>
            </RoleListSection>
          </Grid>
          <Grid item xs={10}>
            <RoleAccessListSection>
              <SuperAdmin
                role={selectedIndex}
                permissions={permission}
                noPermission={noPermission}
                roleId={selectedRoleId}
                setOpenDialog={setOpenDialog}
                setPermission={setPermission}
                setSelectedIndex={setSelectedIndex}
                setSelectedRoleId={setSelectedRoleId}
                handleBtnClick={handleBtnClick}
              />
            </RoleAccessListSection>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default ManageAdminUsersRoles;

const Wrapper = styled("section")({
  textAlign: "start",
  width: "100%",
  // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
  padding: '1rem',
});
const H3 = styled("h3")({
  fontSize: "16px",
  fontWeight: "600",
  color: "#1d2739",
  marginTop: "0",
  padding:'15px 0 0 15px',
});

const RoleListSection = styled("div")({
  width: "180px",
  borderRadius:'4px',
  boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
});
const RoleAccessListSection = styled("div")({
});

const HeadDescription = styled("p")({
  fontSize: "16px",
  color: "#1d2739",
  marginTop: "0",
});

const Container = styled("div")({
  margin: "3rem auto",
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'

});
const HeadText = styled("h3")({
  color: "#e85c2a",
  fontSize: "24px",
  textAlign: "start",
  margin: "0",
});

const RoleList = styled("ul")({
  margin: "0 !important",
  padding: "0",
  listStyle: "none",
  ".role-list-item": {
    padding: "15px",
    margin: "0",
    borderLeft: "4px solid transparent",
    boxShadow:
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    "&:hover": {
      backgroundColor: "#FFF0EB",
      cursor: "pointer",
    },
  },
  ".active-role-item":{
    borderLeft: "4px solid #e85c2a",
    backgroundColor: "#FFF0EB",
  }
});

const RoleListItemButton = styled("button")({
  background: "transparent",
  border: "transparent",
  fontSize: "13px",
  fontWeight: "600",
  textTransform: "capitalize",
  width: "180px",
  textAlign: "start",
});
