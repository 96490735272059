import { useTheme } from "@emotion/react";
import { Grid, List, ListItemText, Paper } from "@mui/material";
import { Paragraph, Span } from "components/typography/Typography";
import { useOutletContext } from "react-router-dom";
import { formatString } from "utils/utilFn";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import {
  createDialog,
  notifyFailure,
  notifySuccess,
} from "redux/actions/AlertActions";
import { deleteRole } from "services/https/roleAndAccessService";
import { EDIT_CUSTOM_ROLE } from "utils/constant";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const user = [
  "Adeyanju Akorede",
  "Philip Templar",
  "Adeyanju Gabriel",
  "Clever Eziogor",
  "Samuel Aniefiok",
];

const permission = [
  { name: "Can view dashboard" },
  { name: "Can create role and access for Super Admin" },
  { name: "Can create role and access for others" },
  { name: "Can View all Personal Users" },
  { name: "Can Manage all Personal Users" },
  { name: "Can VIew all Corporate Users" },
  { name: "Can Manage All Corporate Users" },
  { name: "Can View user’s Referals" },
  { name: "Can Manage User’s Referals" },
  { name: "Can Access Waya Official page" },
  { name: "Can View Disputes" },
  { name: "Can Manage Disputes" },
  { name: "Can View QR Codes" },
  { name: "Can Manage QR Codes" },
  { name: "Can View Transactions" },
  { name: "Can Manage Transactions" },
  { name: "Can View OTP" },
  { name: "Can View KYC Configuration" },
  { name: "Can View Admin Tech page" },
  { name: "Can Manage Admin Tech page" },
  { name: "Can View Admin Logs" },
  { name: "Can Manage Admin Logs" },
];

export const SuperAdmin = ({
  role = "ALL ROLES",
  users = user,
  permissions = permission,
  noPermission = [],
  roleId,
  setOpenDialog,
  setPermission,
  setSelectedIndex,
  setSelectedRoleId,
  handleBtnClick,
}) => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const handleDeleteOk = () => {
    deleteRole({ roleId: roleId })
      .then((data) => {
        dispatch(notifySuccess({ msg: "Role Deleted successfully" }));
        setSelectedIndex("ALL ROLES");
        setPermission(permission);
        setSelectedRoleId();
      })
      .catch((err) => notifyFailure({ msg: "Cannot delete Role" }))
      .finally(() => {
        setOpenDialog(false);
      });
  };

  const handleDelete = () => {
    const payload = {
      title: "Confirm Delete",
      subTitle: "Are you sure you want to delete this Role",
      handleOk: handleDeleteOk,
    };
    dispatch(createDialog({ type: "confirmation", payload }));
    setOpenDialog(true);
  };

  return (
    <Wrapper>
      <Container>
        <HeaderSection>
          <HeadContent>
            <h3 className="header-text">{role.toLocaleLowerCase()}</h3>
            <IconButton
              onClick={(e) => handleBtnClick(e, EDIT_CUSTOM_ROLE)}
              color="primary"
              sx={{ visibility: roleId ? "visible" : "hidden" }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={handleDelete}
              color="warning"
              sx={{ visibility: roleId ? "visible" : "hidden" }}
            >
              <DeleteIcon />
            </IconButton>
          </HeadContent>
          <p className="head-description">
            {"This role grants users the permissions listed below."}
          </p>
        </HeaderSection>

        {/* Uncomment when information is returned from backend  */}
        {/* </Grid> */}
        {/* <Grid item xs={12}>
        <Paragraph
          sx={{
            textAlign: "start",
            border: "1px solid grey",
            padding: "8px",
          }}
        >
          <Span>{"10 Members with this role:"}</Span>
          {users?.map((i, idx) => (
            <span key={`${i}-${idx}`}>{`${i}, `}</span>
          ))}
        </Paragraph>


      </Grid> */}
        {/* <Grid item xs={6}>
            <Paper sx={{ textAlign: "start", paddingLeft: theme.spacing(2) }}>
              <List>
                <ListItemText primary={"What this role can do"} />
              </List>
              {permissions?.map((i, idx) => (
                <List key={idx}>
                  <ListItemText primary={formatString(i?.name)} />
                </List>
              ))}
            </Paper>
          </Grid> */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CanRoleList>
              <RoleListItemCan>{role.toLocaleLowerCase()} can</RoleListItemCan>
              {permissions?.map((i, idx) => (
                <RoleListItemCan key={idx}>
                  {formatString(i?.name).toLocaleLowerCase()}
                </RoleListItemCan>
              ))}
            </CanRoleList>
          </Grid>

          <Grid item xs={6}>
            <CannotRoleList>
              <RoleListItemCannot>
                {role.toLocaleLowerCase()} cannot
              </RoleListItemCannot>
              {noPermission?.map((i, idx) => (
                <RoleListItemCannot key={idx}>
                  {formatString(i?.name).toLocaleLowerCase()}
                </RoleListItemCannot>
              ))}
            </CannotRoleList>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  text-align: start;
  width: 100%;
`;
const Container = styled.div`
  width: 90%;
  margin: auto;
`;
const HeaderSection = styled.h3`
  .header-text {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    text-transform: capitalize;
  }
  .head-description {
    font-size: 16px;
    color: #1d2739;
    margin: 0;
  }
`;
const HeadContent = styled.div`
  display: flex;
  align-items: center;
`;

const CanRoleList = styled.ul`
  padding: 0;
  list-style: none;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
`;
const CannotRoleList = styled.ul`
  padding: 0;
  list-style: none;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
`;
const RoleListItemCan = styled.li`
  text-transform: capitalize;
  padding: 10px;
  &:nth-child(1) {
    background-color: #e2f3ea;
    color: #52936f;
  }
  &:nth-child(even) {
    background-color: #f7f7f7;
  }
`;

const RoleListItemCannot = styled.li`
  text-transform: capitalize;
  padding: 10px;
  &:nth-child(1) {
    background-color: #fef2f2;
    color: #c67777;
  }
  &:nth-child(even) {
    background-color: #f7f7f7;
  }
`;
