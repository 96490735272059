import { formatDate } from '../../../../utils/utilFn'

export const dataKeysAndRepr = [

  { 
    objKey: "fullName", repr: "Full Name"},
  // { objKey: "emailAddress", repr: "Full Name" },
  { objKey: "userId", repr: "User ID" },
  { objKey: "role", repr: "Role" },
   { objKey: 'lastLogin', repr: "Last Login" },
  { objKey: "action", repr: "Action" },
];

export const TotalSummary = [
  {
    title: "All Admin Users",
    objKeyRepr: "totalAdminUser",
  },
  // {
  //   title: "Total Pending Invites",
  //   objKeyRepr: "totalInviteesPending",
  // },
];

export const formatAdminData = (data) => {
  return data.map((item) => {
    return {
      ...item,
      createdAt: formatDate(item.createdAt)
    }
  })
}
