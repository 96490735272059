import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
} from "@mui/material";
import { Span } from "components/typography/Typography";
import { useSelector } from "react-redux";
import { getAllRoles } from "services/https/roleAndAccessService";




export const DeleteAdminModal = ({ openDialog, setOpenDialog }) => {
  const {
    alerts: { payload },
  } = useSelector((state) => {
    return state;
  });

  const { handleOk, row, type } = payload || {};
  const [role, setRole] = useState(row?.role || "");
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState([]);

  const handleClick = (e) => {
    setLoading(true);
    const roleId = option.find((item) => item.name === role);
    handleOk(roleId?.id).finally(() => {
      setLoading(false);
      handleClose();
    });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    getAllRoles().then((data) => {
      const myData = JSON.parse(data).map((i) => {
        return { ...i, ["name"]: i.role };
      });
      setOption(myData);
    });
  }, []);

  return (
    <Wrapper>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        width="s"
        open={openDialog}
        onClose={handleClose}
      >
        <HeadContainer>
          <p className="role-header-text">Remove Team Member </p>
          <p className="role-modal-text">
            Are you sure you want to remove{" "}
            <span className="role-owner">{`${row?.fullName}`}</span> from your
            team
          </p>
        </HeadContainer>
        <DialogActions>
          <Button
            variant={"outlined"}
            fullWidth
            sx={{ mx: 2 }}
            onClick={handleClose}
          >
            <Span>{"Cancel"}</Span>
            {loading && (
              <Span sx={{ ml: 1, mt: "4px" }}>
                <CircularProgress sx={{ color: "#fff" }} size={20} />
              </Span>
            )}
          </Button>
          <Button
            variant={"contained"}
            fullWidth
            sx={{ mx: 2 }}
            onClick={handleClick}
          >
            <Span>{"Remove Team Member"}</Span>
            {loading && (
              <Span sx={{ ml: 1, mt: "4px" }}>
                <CircularProgress sx={{ color: "#fff" }} size={20} />
              </Span>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const HeadContainer = styled.div`
  width: 100%;
  background-color: #fff0eb;
  text-align: center;
  .role-header-text {
    color: #8c3819;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 5px;
  }
  .role-modal-text {
    font-weight: 500;
    font-size: 18px;
    margin: 0 0 20px 20px;
  }
  .role-owner {
    font-weight: 700;
  }
`;

