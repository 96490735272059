import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Grid,
} from "@mui/material";
import { Span } from "components/typography/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRoles,
  getMerchantLoginId,
  inviteNewTeamMember,
} from "services/https/roleAndAccessService";
import { useForm, Controller } from "react-hook-form";
import { TextInputWithIcon } from "components/form/textInput";
import emailIcon from "assets/icons/svgIcons/email.svg";
import settingIcon from "assets/icons/svgIcons/setting.svg";
import userIcon from "assets/icons/svgIcons/single-user.svg";
import { SelectInputWithIcon } from "components/form/SelectInput";
import { notifyFailure, notifySuccess } from "redux/actions/AlertActions";
import { getWayaAdminUser } from "services/https/kycService";

export const InviteNewAdminModal = ({ openDialog, setOpenDialog }) => {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      select: {},
    },
  });
  const [loading, setLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [currentMerchant, setCurrentMerchant] = useState();
  const [merchantUserId, setMerchantUserId] = useState();
  const [merchantLoginId, setMerchantLoginId] = useState();

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    getWayaAdminUser().then((data) => {
      setCurrentMerchant(data?.merchantId);
      setMerchantUserId(data?.userId);
    });
    getAllRoles().then((data) => {
      const myData = JSON.parse(data).map((i) => {
        return { ...i, ["name"]: i.role };
      });
      setRoleOptions(myData);
    });
  }, []);

  useEffect(() => {
    getMerchantLoginId(merchantUserId)
      .then((data) => {
        setMerchantLoginId(data?.loginId);
      })
      .catch((error) => {
        console.log(error, "Something went wrong");
      });
  }, [merchantUserId]);

  const onSubmit = (data) => {
    const selectedRoles = data?.role?.map((data) => data?.value);
    const inVitePayload = {
      email: data?.email,
      roleIds: selectedRoles,
      merchantId: currentMerchant,
      merchantUserId: merchantUserId,
      merchantLoginId: merchantLoginId,
      firstName: data?.firstName,
      lastName: data?.lastName,
    };
    console.log(inVitePayload)
    // setLoading(true);
    // inviteNewTeamMember(inVitePayload)
    //   .then((data) => {
    //     setLoading(false);
    //     dispatch(
    //       notifySuccess({ msg: "New Team Member invited successfully" })
    //     );
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     notifyFailure({ msg: "Cannot Invite Team Member" });
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //     setOpenDialog(false);
    //   });
  };

  return (
    <DialogWrapper>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        width="s"
        open={openDialog}
        onClose={handleClose}
      >
        <HeadContainer>
          <p className="role-header-text">Invite a Team Member </p>
        </HeadContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextInputWithIcon
                    {...field}
                    label={"First Name"}
                    placeholder={"Janet"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextInputWithIcon
                    {...field}
                    label={"Last Name"}
                    placeholder={"Doe"}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextInputWithIcon
                {...field}
                type={"email"}
                label={"Email Address"}
                placeholder={"Enter team member’s email address"}
                icon={emailIcon}
              />
            )}
          />
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <SelectInputWithIcon
                {...field}
                label={"Choose Role"}
                isMulti
                placeholder={"Select team member’s Role"}
                icon={settingIcon}
                className="basic-multi-select"
                options={roleOptions?.map((data) => ({
                  label: data.name,
                  value: data.id,
                }))}
                // options={[
                //   { value: "chocolate", label: "Chocolate" },
                //   { value: "strawberry", label: "Strawberry" },
                //   { value: "vanilla", label: "Vanilla" },
                // ]}
              />
            )}
          />
          {/* <input type="submit" /> */}

          <DialogActions>
            <Button
              type="button"
              variant={"outlined"}
              fullWidth
              sx={{ mx: 2 }}
              onClick={handleClose}
            >
              <Span>{"Cancel"}</Span>
            </Button>
            <Button
              variant={"contained"}
              fullWidth
              sx={{ mx: 2 }}
              type="submit"
            >
              <Span>{"Invite Team Member"}</Span>
              {loading && (
                <Span sx={{ ml: 1, mt: "4px" }}>
                  <CircularProgress sx={{ color: "#fff" }} size={20} />
                </Span>
              )}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </DialogWrapper>
  );
};

export const DialogWrapper = styled.div``;

export const HeadContainer = styled.div`
  width: 100%;
  background-color: #fff0eb;
  text-align: center;
  .role-header-text {
    color: #8c3819;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 5px;
  }
`;

export const Form = styled.form`
  padding: 1rem;
`;
